<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUser.christian_name1 }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="picture" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          S
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="d-flex flex-column" style="width: 100%">
            <!-- <button
              class="btn btn-primary btn-bold text-white mt-6 mb-7"
              color="blue darken-1"

              style="width: 60%; margin: auto"
              @click="onLogout"
            >
              {{ $t("AUTH.SIGN_OUT_BUTTON") }}
            </button>
-->
            <button
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
              :style="buttonStyle"
              @click="onLogout"
            >
              {{ $t("AUTH.SIGN_OUT_BUTTON") }}
            </button>

            <v-form v-model="userFormValid" ref="userFormValid">
              <div class="navi mt-2 m-auto">
                <v-divider></v-divider>
                <div class="mt-7 mb-7">
                  {{ $t("MENU.userGroups") }}:
                  <br />
                  <span class="navi-text text-muted text-hover-primary">
                    {{ userGroups.join(", ") }}
                  </span>
                </div>
                <!-- <div style="display: flex; width: 100%"> -->
                <v-text-field
                  v-if="permissionCan('view.self')"
                  :disabled="!permissionCan('update.self')"
                  v-model="currentUser.christian_name1"
                  :rules="requiredRules"
                  :label="$t('FORMS.firstName')"
                ></v-text-field>
                <v-text-field
                  v-if="permissionCan('view.self')"
                  :disabled="!permissionCan('update.self')"
                  v-model="currentUser.christian_name2"
                  :label="$t('FORMS.middleName')"
                ></v-text-field>
                <v-text-field
                  v-if="permissionCan('view.self')"
                  :disabled="!permissionCan('update.self')"
                  v-model="currentUser.surname"
                  :rules="requiredRules"
                  :label="$t('FORMS.lastName')"
                ></v-text-field>
                <!-- </div> -->
                <span class="navi-text text-muted text-hover-primary">
                  <v-text-field
                    v-if="permissionCan('view.self')"
                    :disabled="!permissionCan('update.self')"
                    v-model="currentUser.email"
                    :rules="requiredRules"
                    label="Email"
                  ></v-text-field>
                </span>
                <v-text-field
                  v-if="permissionCan('view.self')"
                  :disabled="!permissionCan('update.self')"
                  :append-icon="eyeIcon"
                  v-model="currentUser.password"
                  :label="$t('FORMS.password')"
                  :type="passIntputType"
                  @click:append="toggleShowPass"
                ></v-text-field>
              </div>
            </v-form>

            <button
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
              :style="buttonStyle"
              @click="handleSaveUser"
            >
              {{ $t("FORMS.save") }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "KTQuickUser",
  data() {
    return {
      userFormValid: false,
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      // languageFlag: "",
      // languages: i18nService.languages,
      passIntputType: "password",
      eyeIcon: "mdi-eye",
      permissions: [],
      buttonStyle: {
        backgroundColor: "#232341",
        borderColor: "transparent",
        margin: "0 auto",
      },
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.setPermissions();
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    handleSaveUser() {
      this.$refs.userFormValid.validate();

      if (this.userFormValid) {
        let currentUser = Object.assign({}, this.currentUser);
        // currentUser.roles = currentUser.roles.map((item) => item.id);
        currentUser.user_groups = currentUser.user_groups.map(
          (item) => item.id
        );

        currentUser.roles = currentUser.roles.map(
          (item) => item.id
        );

        currentUser.sites = currentUser.site;

        ApiService.put("user/" + this.currentUser.id, currentUser);
        // .then(() => {
        //   this.fetchUser();
        // })
        // .catch((error) => {
        //   console.log("Error!: ", error);
        // });
      }
    },
    toggleShowPass() {
      if (this.eyeIcon == "mdi-eye-off") {
        this.passIntputType = "password";
        this.eyeIcon = "mdi-eye";
      } else {
        this.passIntputType = "text";
        this.eyeIcon = "mdi-eye-off";
      }
    },
    permissionCan(action) {
      return this.permissions.find((x) => x.name == "system.user." + action);
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("system.user") > -1
        );
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    userGroups() {
      let userGroups;

      if (
        this.currentUser.user_groups &&
        this.currentUser.user_groups.length > 0
      ) {
        userGroups = this.currentUser.user_groups.map((item) => {
          return this.$helpers.getTranslated(item.translations).name;
        });
      } else {
        userGroups = [];
      }
      return userGroups;
    },
  },
};
</script>
