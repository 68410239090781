var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(_vm.permissionCan('module.humanResources.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/humanResource') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('humanResources.employee.menu'))?_c('router-link',{attrs:{"to":"/humanResource/employees"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v("Employees")])])])]}}],null,false,526270439)}):_vm._e()],1)]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('humanResources.education.menu'))?_c('router-link',{attrs:{"to":"/humanResource/educations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-presentation-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Educations")])])])]}}],null,false,2065995847)}):_vm._e()],1)]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('humanResources.position.menu'))?_c('router-link',{attrs:{"to":"/humanResource/positions"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-map"}),_c('span',{staticClass:"menu-text"},[_vm._v("Positions")])])])]}}],null,false,1693360930)}):_vm._e()],1)]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('humanResources.position.menu'))?_c('router-link',{attrs:{"to":"/humanResource/advances"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-map"}),_c('span',{staticClass:"menu-text"},[_vm._v("Advances")])])])]}}],null,false,2381023777)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.stock.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/stock') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('stock.itemEntity.menu'))?_c('router-link',{attrs:{"to":"/stock/stockItems"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v("Stock list")])])])]}}],null,false,395878874)}):_vm._e(),(_vm.permissionCan('stock.receive.menu'))?_c('router-link',{attrs:{"to":"/stock/receives"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-folder-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Receives")])])])]}}],null,false,3992734213)}):_vm._e(),(_vm.permissionCan('stock.release.menu') || 1)?_c('router-link',{attrs:{"to":"/stock/releases"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-folder-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Releases")])])])]}}],null,false,1441615814)}):_vm._e(),(_vm.permissionCan('stock.transfer.menu'))?_c('router-link',{attrs:{"to":"/stock/transfers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-arrows"}),_c('span',{staticClass:"menu-text"},[_vm._v("Transfers")])])])]}}],null,false,3308176326)}):_vm._e(),(_vm.permissionCan('stock.adjustment.menu'))?_c('router-link',{attrs:{"to":"/stock/adjustments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-7"}),_c('span',{staticClass:"menu-text"},[_vm._v("Adjustments")])])])]}}],null,false,877955799)}):_vm._e(),(_vm.permissionCan('stock.storage.menu'))?_c('router-link',{attrs:{"to":"/stock/storages"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Storages")])])])]}}],null,false,1757047633)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.inventory.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/inventory') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('inventory.item.menu'))?_c('router-link',{attrs:{"to":"/inventory/items"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-tabs"}),_c('span',{staticClass:"menu-text"},[_vm._v("Item Catalogue")])])])]}}],null,false,2940223910)}):_vm._e(),(_vm.permissionCan('inventory.priceList.menu'))?_c('router-link',{attrs:{"to":"/inventory/pricelists"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-price-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v("Price List")])])])]}}],null,false,737030121)}):_vm._e(),(_vm.permissionCan('inventory.itemCategory.menu'))?_c('router-link',{attrs:{"to":"/inventory/categories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v("Categories")])])])]}}],null,false,2670785746)}):_vm._e(),(_vm.permissionCan('inventory.itemCategory.menu'))?_c('router-link',{attrs:{"to":"/inventory/webCategories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Web Categories")])])])]}}],null,false,3181697641)}):_vm._e(),(_vm.permissionCan('inventory.quantityUnit.menu'))?_c('router-link',{attrs:{"to":"/inventory/quantityunits"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-dashboard"}),_c('span',{staticClass:"menu-text"},[_vm._v("Quantity Units")])])])]}}],null,false,2192679710)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.sales.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/sales') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(3),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('sales.salesOrder.menu'))?_c('router-link',{attrs:{"to":"/sales/salesorders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sales Orders")])])])]}}],null,false,580421201)}):_vm._e(),(_vm.permissionCan('sales.salesPoint.menu'))?_c('router-link',{attrs:{"to":"/sales/salesPoints"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-business"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sales Points")])])])]}}],null,false,2776100677)}):_vm._e(),(_vm.permissionCan('sales.sourceType.menu'))?_c('router-link',{attrs:{"to":"/sales/sourceTypes"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-graphic-design"}),_c('span',{staticClass:"menu-text"},[_vm._v("Source Types")])])])]}}],null,false,1579556137)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.transport.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/transport') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(4),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('transport.transportOrder.menu'))?_c('router-link',{attrs:{"to":"/transport/transportOrders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-contract"}),_c('span',{staticClass:"menu-text"},[_vm._v("Transport orders")])])])]}}],null,false,4093378442)}):_vm._e(),(_vm.permissionCan('transport.itiner.menu'))?_c('router-link',{attrs:{"to":"/transport/itiners"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-map-location"}),_c('span',{staticClass:"menu-text"},[_vm._v("Daily itiners")])])])]}}],null,false,2641240063)}):_vm._e(),(_vm.permissionCan('transport.vehicle.menu'))?_c('router-link',{attrs:{"to":"/transport/vehicles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-truck"}),_c('span',{staticClass:"menu-text"},[_vm._v("Vehicles")])])])]}}],null,false,743700132)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.manufacture.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/manufacture') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(5),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('manufacture.project.menu'))?_c('router-link',{attrs:{"to":"/manufacture/projects"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-event-calendar-symbol"}),_c('span',{staticClass:"menu-text"},[_vm._v("Projects")])])])]}}],null,false,2903314922)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.procurement.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/procurement') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(6),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('procurement.purchaseOrder.menu'))?_c('router-link',{attrs:{"to":"/procurement/purchaseOrder"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-indent-dots"}),_c('span',{staticClass:"menu-text"},[_vm._v("Purchase orders")])])])]}}],null,false,3577604749)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.maintenance.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/maintenance') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(7),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('maintenance.equipment.menu'))?_c('router-link',{attrs:{"to":"/maintenance/equipments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-10"}),_c('span',{staticClass:"menu-text"},[_vm._v("Equipments")])])])]}}],null,false,1965640132)}):_vm._e(),(_vm.permissionCan('maintenance.equipment.menu'))?_c('router-link',{attrs:{"to":"/maintenance/calendar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-calendar-with-a-clock-time-tools"}),_c('span',{staticClass:"menu-text"},[_vm._v("Calendar")])])])]}}],null,false,4171605804)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('system.mediaLibrary.menu'))?_c('router-link',{attrs:{"to":"/media_library"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.MEDIA_LIBRARY")))])])])]}}],null,false,3212763797)}):_vm._e(),(_vm.permissionCan('module.contacts.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/contacts') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(8),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('contacts.contact.menu'))?_c('router-link',{attrs:{"to":"/contacts/contactList"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-calendar-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contact List")])])])]}}],null,false,3946216232)}):_vm._e(),(_vm.permissionCan('contacts.companyType.menu'))?_c('router-link',{attrs:{"to":"/contacts/companyTypes"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v("Company Types")])])])]}}],null,false,1310764760)}):_vm._e(),(_vm.permissionCan('contacts.companyCategory.menu'))?_c('router-link',{attrs:{"to":"/contacts/companyCategories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-files-and-folders"}),_c('span',{staticClass:"menu-text"},[_vm._v("Company Categories")])])])]}}],null,false,3115892588)}):_vm._e(),(_vm.permissionCan('contacts.industry.menu'))?_c('router-link',{attrs:{"to":"/contacts/industries"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-rocket"}),_c('span',{staticClass:"menu-text"},[_vm._v("Industries")])])])]}}],null,false,1457178750)}):_vm._e(),(_vm.permissionCan('contacts.qualification.menu'))?_c('router-link',{attrs:{"to":"/contacts/qualifications"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-medal"}),_c('span',{staticClass:"menu-text"},[_vm._v("Qualifications")])])])]}}],null,false,1457758647)}):_vm._e(),(_vm.permissionCan('contacts.supplierType.menu'))?_c('router-link',{attrs:{"to":"/contacts/supplierTypes"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-truck"}),_c('span',{staticClass:"menu-text"},[_vm._v("Supplier Types")])])])]}}],null,false,2746109203)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.system.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/settings') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-settings-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.systemSettings")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('system.user.menu'))?_c('router-link',{attrs:{"to":"/settings/users"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.users")))])])])]}}],null,false,1625818440)}):_vm._e(),(_vm.permissionCan('system.userGroup.menu'))?_c('router-link',{attrs:{"to":"/settings/userGroups"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.userGroups")))])])])]}}],null,false,3382400261)}):_vm._e(),(_vm.permissionCan('system.role.menu'))?_c('router-link',{attrs:{"to":"/settings/roles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-8"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.roles")))])])])]}}],null,false,411942562)}):_vm._e(),(_vm.permissionCan('system.currency.menu'))?_c('router-link',{attrs:{"to":"/settings/currency"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-coins"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.currencies")))])])])]}}],null,false,2953195404)}):_vm._e(),(_vm.permissionCan('system.country.menu'))?_c('router-link',{attrs:{"to":"/settings/country"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-earth-globe"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.countries")))])])])]}}],null,false,869823423)}):_vm._e()],1)])]):_vm._e(),(_vm.permissionCan('module.companySettings.menu'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/companysettings') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-map"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.companySettings")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.permissionCan('companySettings.department.menu'))?_c('router-link',{attrs:{"to":"/companysettings/departments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-squares-4"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.departments")))])])])]}}],null,false,2790414057)}):_vm._e(),(_vm.permissionCan('companySettings.site.menu'))?_c('router-link',{attrs:{"to":"/companysettings/sites"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.sites")))])])])]}}],null,false,3615678534)}):_vm._e(),(_vm.permissionCan('companySettings.site.menu'))?_c('router-link',{attrs:{"to":"/companysettings/websites"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MENU.websites")))])])])]}}],null,false,1045410644)}):_vm._e()],1)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-network"}),_c('span',{staticClass:"menu-text"},[_vm._v("Human resource")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-interface-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Stock")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-folder-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Inventory")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-shopping-cart-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sales")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-delivery-truck"}),_c('span',{staticClass:"menu-text"},[_vm._v("Transport")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-cart"}),_c('span',{staticClass:"menu-text"},[_vm._v("Manufacture")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-download-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Procurement")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-gear"}),_c('span',{staticClass:"menu-text"},[_vm._v("Maintenance")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-support"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contacts")]),_c('i',{staticClass:"menu-arrow"})])
}]

export { render, staticRenderFns }