<template>
  <!-- begin:: Footer -->
  <div class="footer" id="kt_footer" data-app>
    <v-row justify="center">
      <v-dialog v-model="expiredDialog" max-width="350" persistent>
        <v-card>
          <v-card-title class="headline">
            Your session will be expired,</v-card-title
          >

          <v-card-text> would you like to continue? </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="handleClose()">
              No
            </v-btn>

            <v-btn color="green darken-1" text @click="handleAgree()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>

  <!-- end:: Footer -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "KTFooter",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["layoutConfig", "expiredDialog"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
  methods: {
    ...mapActions(["closeExpiredDialog", "logout", "verifyAuth"]),
    handleAgree() {
      this.closeExpiredDialog(false);
      this.verifyAuth();
      ApiService.post("tokenRefresh", {
        token: window.localStorage.getItem("id_token"),
      }).then(({ data }) => {
        if (data.token) {
          window.localStorage.setItem("id_token", data.token);
        }
      });

      // this.$router.go(this.$router.currentRoute);
    },
    handleClose() {
      this.logout();
      this.$router.go(this.$router.login);
    },
  },
};
</script>
