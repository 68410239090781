<template>
  <ul class="menu-nav">
    <li
      v-if="permissionCan('module.humanResources.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/humanResource') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-network"></i>
        <span class="menu-text">Human resource</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('humanResources.employee.menu')"
            to="/humanResource/employees"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-users"></i>
                <span class="menu-text">Employees</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('humanResources.education.menu')"
            to="/humanResource/educations"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-presentation-1"></i>
                <span class="menu-text">Educations</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('humanResources.position.menu')"
            to="/humanResource/positions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-map"></i>
                <span class="menu-text">Positions</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('humanResources.position.menu')"
            to="/humanResource/advances"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-map"></i>
                <span class="menu-text">Advances</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.stock.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/stock') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-interface-3"></i>
        <span class="menu-text">Stock</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!-- <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Vue Bootstrap</span>
            </span>
          </li> -->
          <!--  -->
          <router-link
            v-if="permissionCan('stock.itemEntity.menu')"
            to="/stock/stockItems"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-clipboard"></i>
                <span class="menu-text">Stock list</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="permissionCan('stock.receive.menu')"
            to="/stock/receives"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-folder-2"></i>
                <span class="menu-text">Receives</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('stock.release.menu') || 1"
            to="/stock/releases"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-folder-3"></i>
                <span class="menu-text">Releases</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('stock.transfer.menu')"
            to="/stock/transfers"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-arrows"></i>
                <span class="menu-text">Transfers</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('stock.adjustment.menu')"
            to="/stock/adjustments"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-interface-7"></i>
                <span class="menu-text">Adjustments</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="permissionCan('stock.storage.menu')"
            to="/stock/storages"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-home"></i>
                <span class="menu-text">Storages</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.inventory.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/inventory') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-folder-1"></i>
        <span class="menu-text">Inventory</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!-- <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Vue Bootstrap</span>
            </span>
          </li> -->
          <!--  -->
          <router-link
            v-if="permissionCan('inventory.item.menu')"
            to="/inventory/items"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-tabs"></i>
                <span class="menu-text">Item Catalogue</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('inventory.priceList.menu')"
            to="/inventory/pricelists"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-price-tag"></i>
                <span class="menu-text">Price List</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('inventory.itemCategory.menu')"
            to="/inventory/categories"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-layers"></i>
                <span class="menu-text">Categories</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('inventory.itemCategory.menu')"
            to="/inventory/webCategories"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-interface-3"></i>
                <span class="menu-text">Web Categories</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('inventory.quantityUnit.menu')"
            to="/inventory/quantityunits"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-dashboard"></i>
                <span class="menu-text">Quantity Units</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      v-if="permissionCan('module.sales.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/sales') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-shopping-cart-1"></i>
        <span class="menu-text">Sales</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('sales.salesOrder.menu')"
            to="/sales/salesorders"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-list"></i>
                <span class="menu-text">Sales Orders</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="permissionCan('sales.salesPoint.menu')"
            to="/sales/salesPoints"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-business"></i>
                <span class="menu-text">Sales Points</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('sales.sourceType.menu')"
            to="/sales/sourceTypes"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-graphic-design"></i>
                <span class="menu-text">Source Types</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.transport.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/transport') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-delivery-truck"></i>
        <span class="menu-text">Transport</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('transport.transportOrder.menu')"
            to="/transport/transportOrders"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-contract"></i>
                <span class="menu-text">Transport orders</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="permissionCan('transport.itiner.menu')"
            to="/transport/itiners"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-map-location"></i>
                <span class="menu-text">Daily itiners</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="permissionCan('transport.vehicle.menu')"
            to="/transport/vehicles"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-truck"></i>
                <span class="menu-text">Vehicles</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.manufacture.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/manufacture') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-cart"></i>
        <span class="menu-text">Manufacture</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('manufacture.project.menu')"
            to="/manufacture/projects"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-event-calendar-symbol"></i>
                <span class="menu-text">Projects</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.procurement.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/procurement') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-download-1"></i>
        <span class="menu-text">Procurement</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('procurement.purchaseOrder.menu')"
            to="/procurement/purchaseOrder"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-indent-dots"></i>
                <span class="menu-text">Purchase orders</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.maintenance.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/maintenance') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-gear"></i>
        <span class="menu-text">Maintenance</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('maintenance.equipment.menu')"
            to="/maintenance/equipments"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-interface-10"></i>
                <span class="menu-text">Equipments</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('maintenance.equipment.menu')"
            to="/maintenance/calendar"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i
                  class="menu-icon flaticon-calendar-with-a-clock-time-tools"
                ></i>
                <span class="menu-text">Calendar</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      v-if="permissionCan('system.mediaLibrary.menu')"
      to="/media_library"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-folder"></i>
          <span class="menu-text">{{ $t("MENU.MEDIA_LIBRARY") }}</span>
        </a>
      </li>
    </router-link>

    <li
      v-if="permissionCan('module.contacts.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/contacts') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-support"></i>
        <span class="menu-text">Contacts</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!-- <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Vue Bootstrap</span>
            </span>
          </li> -->
          <!--  -->
          <router-link
            v-if="permissionCan('contacts.contact.menu')"
            to="/contacts/contactList"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-calendar-3"></i>
                <span class="menu-text">Contact List</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('contacts.companyType.menu')"
            to="/contacts/companyTypes"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-tag"></i>
                <span class="menu-text">Company Types</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('contacts.companyCategory.menu')"
            to="/contacts/companyCategories"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-files-and-folders"></i>
                <span class="menu-text">Company Categories</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('contacts.industry.menu')"
            to="/contacts/industries"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-rocket"></i>
                <span class="menu-text">Industries</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('contacts.qualification.menu')"
            to="/contacts/qualifications"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-medal"></i>
                <span class="menu-text">Qualifications</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('contacts.supplierType.menu')"
            to="/contacts/supplierTypes"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-delivery-truck"></i>
                <span class="menu-text">Supplier Types</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.system.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/settings') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-settings-1"></i>
        <span class="menu-text">{{ $t("MENU.systemSettings") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('system.user.menu')"
            to="/settings/users"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                <i class="menu-icon flaticon2-user-1"></i>
                <span class="menu-text">{{ $t("MENU.users") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('system.userGroup.menu')"
            to="/settings/userGroups"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-group"></i>
                <span class="menu-text">{{ $t("MENU.userGroups") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('system.role.menu')"
            to="/settings/roles"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-interface-8"></i>
                <span class="menu-text">{{ $t("MENU.roles") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('system.currency.menu')"
            to="/settings/currency"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-coins"></i>
                <span class="menu-text">{{ $t("MENU.currencies") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('system.country.menu')"
            to="/settings/country"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t("MENU.countries") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="permissionCan('module.companySettings.menu')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/companysettings') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-map"></i>
        <span class="menu-text">{{ $t("MENU.companySettings") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            v-if="permissionCan('companySettings.department.menu')"
            to="/companysettings/departments"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-squares-4"></i>
                <span class="menu-text">{{ $t("MENU.departments") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('companySettings.site.menu')"
            to="/companysettings/sites"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">{{ $t("MENU.sites") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="permissionCan('companySettings.site.menu')"
            to="/companysettings/websites"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-website"></i>
                <span class="menu-text">{{ $t("MENU.websites") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  data() {
    return {
      permissions: [],
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => {
            return (
              item.name.indexOf("menu") > -1 || item.name.indexOf("modul") > -1
            );
          }
        );
      }
    },
  },

  mounted() {
    this.setPermissions();
  },
};
</script>
